import { createPortal } from "react-dom";
import SocolSVG from "@/lib/ui/img/icons/SO - COL.svg";

interface IPageLoaderProps {
  isShown: boolean;
}

export default function PageLoader({ isShown }: IPageLoaderProps) {
  if (typeof document === "object") {
    return createPortal(
      <>
        <div
          className={`fixed top-0 duration-500 transition-opacity left-0 flex justify-center items-center w-full h-full bg-white z-[99999999]`}
        >
          <div className="max-w-[236px] max-h-[236px] min-w-[236px] min-h-[236px] relative overflow-hidden rounded-[50%]">
            <span className="absolute w-full h-full rounded-[50%] animate-spin bg-gradient-to-b from-purple-100 to-purple-10/0.5 " />
            <span className="absolute flex items-center justify-center rounded-[50%] top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] bg-white w-full h-full max-w-[166px] max-h-[166px] text-red-500">
              <SocolSVG />
            </span>
          </div>
        </div>
      </>,
      document.documentElement
    );
  }
  return <></>;
}
