import { createReactQueryHooks } from "@trpc/react";
import type { AppRouter } from "@socol/api/lib/routers/_app";
import type { inferProcedureOutput, inferProcedureInput } from "@trpc/server";
import superjson from "superjson";
import devalue from "devalue";

/**
 * @link https://trpc.io/docs/react#3-create-trpc-hooks
 */
export const trpc = createReactQueryHooks<AppRouter>();

type DataTransformer = {
  serialize(object: any): any;
  deserialize(object: any): any;
};

type CombinedDataTransformer = {
  input: DataTransformer;
  output: DataTransformer;
};

export const transformer: CombinedDataTransformer = {
  input: superjson,
  output: {
    serialize: (object) => devalue(object),
    deserialize: (object) => {
      try {
        return eval(`(${object})`);
      } catch (e1) {
        try {
          return eval(object);
        } catch (e2) {
          console.log(e1, e2);
        }
      }
    },
  },
};

/**
 * This is a helper method to infer the output of a query resolver
 * @example type HelloOutput = inferQueryOutput<'hello'>
 */
export type inferQueryOutput<
  TRouteKey extends keyof AppRouter["_def"]["queries"]
> = inferProcedureOutput<AppRouter["_def"]["queries"][TRouteKey]>;

export type inferQueryInput<
  TRouteKey extends keyof AppRouter["_def"]["queries"]
> = inferProcedureInput<AppRouter["_def"]["queries"][TRouteKey]>;

export type inferMutationOutput<
  TRouteKey extends keyof AppRouter["_def"]["mutations"]
> = inferProcedureOutput<AppRouter["_def"]["mutations"][TRouteKey]>;

export type inferMutationInput<
  TRouteKey extends keyof AppRouter["_def"]["mutations"]
> = inferProcedureInput<AppRouter["_def"]["mutations"][TRouteKey]>;

export type { AppRouter };
