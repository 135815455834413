import { USER_TOKEN_COOKIE_NAME } from "@socol/config/lib/constants/auth";

export function getAuthHeader() {
  if (typeof document !== "undefined") {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${USER_TOKEN_COOKIE_NAME}=`);
    const token = parts.length === 2 ? parts?.pop()?.split(";")?.shift() : "";

    return `Bearer ${token}`;
  }
  return "";
}

export function setAuthToken(token: string) {
  if (typeof window !== "undefined") {
    localStorage.setItem(USER_TOKEN_COOKIE_NAME, token);
  }
}

export function unsetAuthToken() {
  if (typeof window !== "undefined") {
    localStorage.removeItem(USER_TOKEN_COOKIE_NAME);
  }
}
