import "@/lib/styles/globals.css";

import { httpBatchLink } from "@trpc/client/links/httpBatchLink";
import { loggerLink } from "@trpc/client/links/loggerLink";
import { withTRPC } from "@trpc/next";
import { AppType } from "next/dist/shared/lib/utils";
import type { AppRouter } from "@socol/api/lib/routers/_app";
import { transformer } from "@socol/modules/lib/api/trpc";
import { ReactQueryDevtools } from "react-query/devtools";
import { useAnalytics } from "@/hooks/analytics";
import { getAuthHeader } from "@/lib/utils/helpers/auth";
import PageLoader from "@/lib/components/organisms/PageLoader";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const App: AppType = ({ Component, pageProps, router }) => {
  const analytics = useAnalytics();

  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  router?.events?.on("routeChangeStart", () => {
    setIsPageLoading(true);
  });

  router?.events?.on("routeChangeComplete", () => {
    setIsPageLoading(false);
  });

  useEffect(() => {
    const handleRouteChange = () => {
      analytics?.page(document.title);
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [analytics]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
      </Head>
      {isPageLoading && <PageLoader isShown={isPageLoading} />}
      <Component {...pageProps} />
      {process.env.NODE_ENV !== "production" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      <ToastContainer
        autoClose={false}
        closeOnClick={false}
        hideProgressBar
        className={"!w-auto"}
        position="bottom-right"
        toastClassName="!p-0"
        bodyClassName="!p-0 !w-auto"
        closeButton={false}
      />
    </>
  );
};

function getBaseUrl() {
  if (process.browser) {
    return "";
  }

  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }

  return `http://localhost:${process.env.PORT ?? 3000}`;
}

export default withTRPC<AppRouter>({
  config() {
    const url = `${getBaseUrl()}/api/trpc`;
    return {
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        httpBatchLink({
          url,
        }),
      ],
      /**
       * @link https://trpc.io/docs/data-transformers
       */
      transformer,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      queryClientConfig: { defaultOptions: { queries: { staleTime: 1000 } } },
      async headers() {
        return {
          authorization: getAuthHeader(),
        };
      },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: true,
  /**
   * Set headers or status code when doing SSR
   */
  responseMeta({ clientErrors }) {
    if (clientErrors.length) {
      return {
        status: clientErrors[0].data?.httpStatus ?? 500,
      };
    }

    const ONE_DAY_IN_SECONDS = 60 * 60 * 24;
    return {
      headers: {
        "cache-control": `s-maxage=1, stale-while-revalidate=${ONE_DAY_IN_SECONDS}`,
      },
    };
  },
})(App);
