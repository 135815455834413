import { Analytics, AnalyticsBrowser } from "@segment/analytics-next";

export const useAnalytics = (): Analytics | undefined => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);

  const writeKey = process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY;

  useEffect(() => {
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({
        writeKey,
      });

      setAnalytics(response);
    };

    loadAnalytics();
  }, []);

  return analytics;
};
